import { CLink } from '@/CLink';
import { ReactComponent as EmailIcon } from '@a/icons/email.svg';
import { ReactComponent as FacebookIcon } from '@a/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@a/icons/instagram.svg';
import { ReactComponent as PawIcon } from '@a/icons/paw.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as PinIcon } from '@a/icons/pin.svg';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { footerNavLinks, siteInfo } from './NavLinks';
import { smTxt } from './styles/Typography';
import { flexSpace, flexStart, px, py } from './styles/classes';

const Section = styled.footer`
    ${px};
    ${py};
    background-image: ${({ theme }) => theme.colors.darkGradient};
`;

const Flex = styled.div`
    color: ${({ theme }) => theme.colors.white};
    max-width: 1740px;
    margin: 0 auto;

    @media (min-width: 1280px) {
        ${flexSpace};
        align-items: flex-start;
        position: relative;
        gap: 16px;
    }
`;

const Box = styled.div`
    margin: 0 auto 32px;
    max-width: 430px;

    > a:first-of-type {
        display: block;
        max-width: 208px;
        transition: opacity 0.3s ease-in-out;

        :hover,
        :focus {
            opacity: 0.7;
        }
    }

    > p {
        ${smTxt};
        margin: 24px 0 0;
    }

    @media (min-width: 1280px) {
        margin: 0;
    }
`;

const Links = styled.div`
    > h4 {
        margin: 0 0 24px;
        color: ${({ theme }) => theme.colors.white};
        font-weight: 500;
    }

    > div {
        ${flexSpace};
        align-items: flex-start;
        max-width: 340px;
        gap: 16px;
        flex-wrap: wrap;

        > div {
            ${flexStart};
            flex-direction: column;

            > a {
                ${smTxt};
                transition: color 0.3s ease-in-out;
                color: ${({ theme }) => theme.colors.white};
                text-transform: capitalize;

                > svg {
                    margin-right: 16px;
                }

                :not(:last-of-type) {
                    margin-bottom: 16px;
                }

                :hover,
                :focus {
                    color: ${({ theme }) => theme.colors.secondary};
                }
            }
        }
    }

    @media (min-width: 600px) {
        max-width: max-content;
        margin: 0 auto;
    }

    @media (min-width: 1280px) {
        margin: 0;

        > div {
            width: 340px;
        }
    }
`;

const QuickLinks = styled.div`
    margin-top: 40px;

    > h4 {
        margin: 0 0 24px;
        color: ${({ theme }) => theme.colors.white};
        font-weight: 500;
    }

    > a {
        ${smTxt};
        ${flexStart};
        align-items: center;
        color: ${({ theme }) => theme.colors.white};
        transition: color 0.3s ease-in-out;

        :not(:last-of-type) {
            margin-bottom: 16px;
        }

        > svg {
            margin-right: 16px;
            flex-shrink: 0;

            > path {
                transition: fill 0.3s ease-in-out;
                fill: ${({ theme }) => theme.colors.secondary};
            }
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.secondary};

            > svg > path {
                fill: ${({ theme }) => theme.colors.white};
            }
        }
    }

    > div {
        ${flexStart};
        align-items: flex-start;
        gap: 24px;
        margin-top: 24px;

        > a {
            svg path {
                transition: fill 0.3s ease-in-out;
            }

            :hover,
            :focus {
                svg path {
                    fill: ${({ theme }) => theme.colors.secondary};
                }
            }
        }
    }

    @media (min-width: 600px) {
        margin: 40px auto 0;
        max-width: max-content;
    }

    @media (min-width: 1280px) {
        margin: 0;
    }
`;

const Copyright = styled.p`
    ${smTxt};
    margin: 40px 0 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};

    @media (min-width: 1280px) {
        margin-top: 80px;
    }
`;

export const Footer = () => {
    return (
        <Section>
            <Flex>
                <Box>
                    <CLink to="/">
                        <StaticImage
                            src="../../../assets/images/layout/logo.png"
                            alt="advanced veterinary care"
                            width={208}
                            height={64}
                        />
                    </CLink>
                    <p>
                        Advanced veterinary care (AVC) is a state-of-the-art facility, committed to
                        being Central Florida’s veterinary medical center. We consider AVC to be a
                        partner with your family veterinarian, to provide advanced diagnostics,
                        specialty and urgent care services for your pets.
                    </p>
                </Box>
                <Links>
                    <h4>Useful Links</h4>
                    <div>
                        <div>
                            {footerNavLinks.slice(0, 5).map((data, i) => (
                                <CLink key={i} to={data.link}>
                                    <PawIcon />
                                    {data.text}
                                </CLink>
                            ))}
                        </div>
                        <div>
                            {footerNavLinks.slice(5).map((data, i) => (
                                <CLink key={i} to={data.link}>
                                    <PawIcon />
                                    {data.text}
                                </CLink>
                            ))}
                        </div>
                    </div>
                </Links>
                <QuickLinks>
                    <h4>Quick Contact</h4>
                    <CLink to={siteInfo.address.link}>
                        <PinIcon /> {siteInfo.address.text}
                    </CLink>
                    <CLink to={siteInfo.phone.link}>
                        <PhoneIcon /> {siteInfo.phone.text}
                    </CLink>
                    <CLink to={siteInfo.email.link}>
                        <EmailIcon /> {siteInfo.email.text}
                    </CLink>
                    <div>
                        <CLink to={siteInfo.social.facebook}>
                            <FacebookIcon />
                        </CLink>
                        <CLink to={siteInfo.social.instagram}>
                            <InstagramIcon />
                        </CLink>
                    </div>
                </QuickLinks>
            </Flex>

            <Copyright>
                © {new Date().getFullYear()} Advanced Veterinary Care. All Rights Reserved.
            </Copyright>
        </Section>
    );
};
