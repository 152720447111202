import { css } from '@emotion/react';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Form, Input, InputFlex, Label, Thanks } from './FormComponents';
import { PrimaryBtn } from './buttons';

export const AppointmentForm = () => {
    const [submit, setSubmit] = useState(false);
    const [sending, setSending] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setSending(true);

        const data = Object.fromEntries(new FormData(e.currentTarget));

        console.log(JSON.stringify(data));

        // await fetch(`https://api.${window.location.host}/contact`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data),
        // });

        setSubmit(true);
        setSending(false);
        e.target.reset();
    };

    return (
        <Form onSubmit={handleSubmit} submit={submit} id="appointment">
            <InputFlex>
                <Label htmlFor="appointmentPetName">
                    <span>Pet’s Name</span>
                    <Input
                        type="text"
                        id="appointmentPetName"
                        name="petName"
                        placeholder="Enter your pet’s name"
                    />
                </Label>

                <Label htmlFor="petType">
                    <span>Pet Type</span>
                    <Input
                        type="text"
                        id="petType"
                        name="petType"
                        placeholder="Enter your pet’s type"
                    />
                </Label>
            </InputFlex>

            <InputFlex>
                <Label htmlFor="appointmentFirstName">
                    <span>First Name</span>
                    <Input
                        type="text"
                        id="appointmentFirstName"
                        name="firstName"
                        placeholder="Enter owner’s first name"
                    />
                </Label>

                <Label htmlFor="appointmentLastName">
                    <span>Last Name</span>
                    <Input
                        type="text"
                        id="appointmentLastName"
                        name="lastName"
                        placeholder="Enter owner’s last name"
                    />
                </Label>
            </InputFlex>

            <InputFlex>
                <Label htmlFor="appointmentEmail">
                    <span>
                        Email address<strong>*</strong>
                    </span>
                    <Input
                        type="email"
                        id="appointmentEmail"
                        name="email"
                        required
                        placeholder="Enter your email address"
                    />
                </Label>
                <Label htmlFor="appointmentPhone">
                    <span>Phone *</span>
                    <PatternFormat
                        type="tel"
                        format="+1 (###) ###-####"
                        customInput={Input}
                        placeholder="123 456-7890"
                        id="appointmentPhone"
                        name="phone"
                        required
                    />
                </Label>
            </InputFlex>

            <InputFlex>
                <Label htmlFor="appointmentDate">
                    <span>Preferred Date</span>
                    <Input
                        id="appointmentDate"
                        type="date"
                        name="appointmentDate"
                        setColor
                        css={css`
                            text-transform: uppercase;
                            padding: 15px 16px;
                        `}
                    />
                </Label>
                <Label htmlFor="time">
                    <span>Preferred Time</span>
                    <Input as="select" name="time" id="time" defaultValue="a" setColor select>
                        <option value="a" disabled hidden>
                            Morning
                        </option>
                        <option value="Morning">Morning</option>
                        <option value="Afternoon">Afternoon</option>
                        <option value="Evening">Evening</option>
                    </Input>
                </Label>
            </InputFlex>

            <Label htmlFor="details">
                <span>Additional Details</span>
                <Input
                    as="textarea"
                    id="details"
                    name="details"
                    rows={8}
                    placeholder="What is the reason for a visit..."
                />
            </Label>
            <PrimaryBtn big disabled={sending}>
                {sending ? 'Sending...' : 'Book Appointment'}
            </PrimaryBtn>
            <Thanks submit={submit} setSubmit={setSubmit} />
        </Form>
    );
};
