import { CLink } from '@/CLink';
import { ReactComponent as EmailIcon } from '@a/icons/email.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { ReactComponent as PinIcon } from '@a/icons/pin.svg';
import styled from '@emotion/styled';
import { siteInfo } from './NavLinks';
import { h4, txtLine } from './styles/Typography';
import { flexCenter, flexSpace, flexStart, px, py } from './styles/classes';

const Section = styled.section`
    ${px};
    ${py};
    position: relative;
    background-color: #f8fbfe;

    @media (min-width: 1024px) {
        ${flexCenter};
    }
`;

const Info = styled.div`
    margin-bottom: 40px;
    max-width: 440px;

    > h2 {
        ${h4};
        ${txtLine}
        margin: 0 0 8px;
    }

    > h3 {
        margin: 0 0 40px;
        text-transform: capitalize;
    }

    > a {
        ${flexStart};
        align-items: center;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;
        margin-bottom: 20px;
        word-break: break-word;

        > span {
            ${flexCenter};
            flex-shrink: 0;
            margin-right: 24px;
            width: 32px;
            height: 32px;
            background-color: #e2e8ee;
            border-radius: 5px;
            transition: background-color 0.3s ease-in-out;

            > svg {
                flex-shrink: 0;
                width: 16px;
                height: 16px;

                path {
                    fill: ${({ theme }) => theme.colors.primary};
                    transition: fill 0.3s ease-in-out;
                }
            }
        }

        :last-of-type {
            margin-bottom: 40px;

            svg {
                width: 16px;
                height: 16.5px;
            }
        }

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.primary};

            > span {
                background-color: #deedfb;

                > svg path {
                    fill: ${({ theme }) => theme.colors.lightBlue};
                }
            }
        }
    }

    > p {
        ${flexSpace};
        margin: 0 0 16px;
        color: ${({ theme }) => theme.colors.black};

        span {
            text-align: right;

            :first-of-type {
                font-weight: 700;
            }
        }

        :nth-last-of-type(2),
        :last-of-type {
            > span:last-of-type {
                color: #677987;
            }
        }

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        margin: 0;
        width: 50%;
        max-width: 528px;
        margin-right: ${({ theme }) => theme.spacing.lg};
    }
`;

const Iframe = styled.iframe`
    border: 0;
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
    min-height: 200px;
    height: 50vw;
    max-width: 650px;
    max-height: 630px;
    aspect-ratio: 650/630;
`;

export const ContactUs = () => {
    return (
        <Section>
            <Info>
                <h2>Reach Out</h2>
                <h3>contact us</h3>
                <CLink to={siteInfo.address.link}>
                    <span>
                        <PinIcon />
                    </span>
                    {siteInfo.address.text}
                </CLink>
                <CLink to={siteInfo.email.link}>
                    <span>
                        <EmailIcon />
                    </span>
                    {siteInfo.email.text}
                </CLink>
                <CLink to={siteInfo.phone.link}>
                    <span>
                        <PhoneIcon />
                    </span>
                    {siteInfo.phone.text}
                </CLink>
                <p>
                    <span>Monday:</span>
                    <span>8:00 am - 10:00 pm</span>
                </p>
                <p>
                    <span>Tuesday:</span>
                    <span>8:00 am - 10:00 pm</span>
                </p>
                <p>
                    <span>Wednesday:</span>
                    <span>8:00 am - 10:00 pm</span>
                </p>
                <p>
                    <span>Thursday:</span>
                    <span>8:00 am - 10:00 pm</span>
                </p>
                <p>
                    <span>Friday:</span>
                    <span>8:00 am - 10:00 pm</span>
                </p>
                <p>
                    <span>Saturday:</span>
                    <span>Closed</span>
                </p>
                <p>
                    <span>Sunday:</span>
                    <span>Closed</span>
                </p>
            </Info>

            <Iframe
                title="advanced veterinary care location on map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14018.642354261514!2d-81.4919123!3d28.5499199!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e77919a2e701dd%3A0xd9d187f3fd699cf4!2sAdvanced%20Veterinary%20Care!5e0!3m2!1sen!2s!4v1691985408976!5m2!1sen!2s"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></Iframe>
        </Section>
    );
};
