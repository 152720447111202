import { CLink } from '@/CLink';
import { PrimaryBtn } from '@/buttons';
import { plainBtnStyles } from '@/buttons/PlainBtn';
import { ReactComponent as ShopIcon } from '@a/icons/shop.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { DesktopNav, NavItem } from './DesktopNavPrimitive';
import { MobNav } from './MobNav';
import { getNavLink, navLinks } from './NavLinks';
import { theme } from './styles/GlobalStyles';
import { flexStart } from './styles/classes';

const Bar = styled.header`
    ${flexStart};
    align-items: center;
    background-color: ${({ theme }) => theme.colors.dark};
    padding-left: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;

    > div:first-of-type {
        width: 100%;
    }

    @media (min-width: 768px) {
        padding-left: 24px;
    }
`;

const HomeLink = styled(CLink)`
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;

    :hover {
        opacity: 0.7;
    }

    :focus-visible {
        opacity: 0.7;
    }

    @media (max-width: 767px) {
        > .gatsby-image-wrapper {
            width: 98px;
            height: 30.15px;
        }
    }
`;

const menuStyles = css`
    > li {
        position: relative; // for submenu align with item

        :first-of-type {
            margin-left: auto;
        }

        :nth-of-type(5) {
            margin-right: auto;
        }

        :nth-last-of-type(3) {
            > a {
                margin-right: 0;
            }
        }
    }

    @media (max-width: 1359px) {
        > * {
            display: none;
        }

        > li {
            :nth-last-of-type(2) {
                display: flex;
                margin-left: auto;
            }
        }
    }

    @media (min-width: 768px) {
        > li {
            :last-of-type,
            :nth-last-of-type(2) {
                display: flex;
            }
        }
    }
`;

const contentStyles = css`
    padding: 8px 0 24px;
    box-shadow: 0px 12px 40px rgba(44, 128, 195, 0.2);
    border-radius: 8px;
    background-color: ${theme.colors.white};
    position: absolute;
    top: calc((1.125rem * 1.62) + 25px);
    width: 350px;
    z-index: 100;
`;

const ShopBtn = styled(CLink)`
    ${plainBtnStyles};
    padding: 25px 16px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.dark};
    transition: color 0.3s ease-in-out;

    > svg {
        margin-left: 0;
        margin-right: 8px;

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    @media (max-width: 767px) {
        position: relative;
        background-color: transparent;
        padding: 18px 0;

        > svg {
            margin: 0;

            > path {
                fill: ${({ theme }) => theme.colors.white};
            }
        }

        > span {
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            position: absolute;
            width: 1px;
        }
    }

    :hover {
        color: ${({ theme }) => theme.colors.white};

        > svg path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }

    :focus-visible {
        color: ${({ theme }) => theme.colors.white};

        > svg path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }
`;

const AppointmentBtn = styled(PrimaryBtn)`
    border-radius: 0;
    padding: 25px 16px;
`;

export const HomeLogo = () => (
    <HomeLink to="/">
        <StaticImage
            src="../../../assets/images/layout/logo.png"
            alt="advanced veterinary-care"
            width={130}
            height={40}
        />
    </HomeLink>
);

// link can be /meet-the-doctors/#dr-herbert or #dr-herbert
export const scrollToSection = (link: string) => {
    const fullLink = typeof link === 'string' ? link.split('#') : '';
    const element = document.querySelector(`#${fullLink[1] || ''}`);
    if (!element) {
        navigate(fullLink[0], {
            state: {
                tag: `#${fullLink[1] || ''}`,
            },
        });
    }
    const y = element && element.getBoundingClientRect().top + window.scrollY - 76;
    window.scroll({
        behavior: 'smooth',
        top: y ? y - 90 : 0,
    });
};

export const Nav = () => {
    return (
        <Bar>
            <HomeLogo />

            <DesktopNav
                menuStyles={menuStyles}
                contentStyles={contentStyles}
                links={navLinks.slice(0, -2)}
            >
                <NavItem>
                    <ShopBtn to="/shop-now/">
                        <ShopIcon /> <span>Shop Now</span>
                    </ShopBtn>
                </NavItem>

                <NavItem>
                    <AppointmentBtn onClick={() => scrollToSection(getNavLink('book appointment'))}>
                        Book Appointment
                    </AppointmentBtn>
                </NavItem>
            </DesktopNav>
            <MobNav />
        </Bar>
    );
};
