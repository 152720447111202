import { plainBtnStyles } from '@/buttons/PlainBtn';
import { css } from '@emotion/react';
import { flexSpace } from './styles/classes';
import { theme } from './styles/GlobalStyles';

const dynamicStyles = css`
    &[aria-current='page'] {
        color: ${theme.colors.primary};
    }

    &.current-nav-category {
        color: ${theme.colors.primary};

        > svg path {
            fill: ${theme.colors.primary};
        }
    }

    &[data-mobile-expanded],
    &[aria-expanded='true'] {
        > svg {
            transform: rotate(180deg);
        }
    }

    :hover {
        color: ${theme.colors.secondary};

        > svg path {
            fill: ${theme.colors.secondary};
        }
    }

    :focus-visible {
        color: ${theme.colors.secondary};

        > svg path {
            fill: ${theme.colors.secondary};
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    text-transform: capitalize;
    color: ${theme.colors.white};
    transition: color 0.3s ease-in-out;
    margin-bottom: 40px;
    font-size: 1.125rem;
    font-weight: 400;

    @media (min-height: 700px) {
        font-size: 1.25rem;
        margin-bottom: 48px;
    }

    @media (min-height: 800px) {
        margin-bottom: 64px;
    }

    @media (min-width: 1360px) {
        font-size: 1.125rem;
        margin-bottom: 0 !important;
        margin-right: 32px;
    }
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};
    font-size: 1rem;
    margin-bottom: 24px;

    > svg path {
        transition: fill 0.3s ease-in-out;
    }

    @media (min-height: 700px) {
        margin-bottom: 32px;
        font-size: 1.125rem;
    }

    @media (min-height: 800px) {
        margin-bottom: 40px;
    }

    @media (min-width: 1360px) {
        ${flexSpace};
        color: ${theme.colors.gray};
        padding: 16px 24px;
        border-bottom: 1px solid ${theme.colors.primary};
        background-color: ${theme.colors.white};
        width: 100%;
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};
    z-index: 20;
    margin: 0 auto 40px;
    font-weight: 500;

    > svg {
        margin-left: 12px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.white};
        }
    }

    @media (min-width: 1360px) {
        margin-bottom: 0 !important;
        font-weight: 400;
    }
`;
