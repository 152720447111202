import { CLink } from '@/CLink';
import { ReactComponent as ChevronIcon } from '@a/icons/chevron.svg';
import { ReactComponent as NavChevronIcon } from '@a/icons/right-chevron.svg';
import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from '@mdx-js/react/lib';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import useLocationData from '@s/hooks/useLocationData';
import { NavLinksTypes } from './NavLinks';
import { navBtnStyles, navLinkStyles, subNavLinkStyles } from './NavLinkStyles';
import { flexCenter } from './styles/classes';

const MenuList = styled(NavigationMenu.List)`
    ${flexCenter};
    justify-content: flex-start;
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
`;

const Content = styled(NavigationMenu.Content)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    animation-duration: 300ms;
    animation-timing-function: ease;

    > ul {
        list-style-type: none;
        padding-inline-start: 0;
        margin: 0;
        max-width: 1440px;
    }

    &[data-state='open'],
    &[data-motion='from-start'] {
        animation-name: enterFromBottom;
    }

    &[data-motion='from-end'] {
        animation-name: enterFromBottom;
    }

    &[data-state='closed'],
    &[data-motion='to-start'] {
        animation-name: exitToBottom;
    }
    &[data-motion='to-end'] {
        animation-name: exitToBottom;
    }

    @keyframes enterFromBottom {
        from {
            opacity: 0;
            transform: translateY(50px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes exitToBottom {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(50px);
        }
    }
`;

type MenuProps = {
    children: ReactNode;
    menuStyles?: SerializedStyles;
    contentStyles: SerializedStyles;
    links: NavLinksTypes;
};

export const DesktopNav = ({ children, menuStyles, contentStyles, links }: MenuProps) => {
    const { title, category } = useLocationData();

    return (
        <NavigationMenu.Root asChild>
            <MenuList css={menuStyles}>
                {links.map((data, i) =>
                    data.links ? (
                        <NavigationMenu.Item key={i}>
                            <NavigationMenu.Trigger
                                css={navBtnStyles}
                                className={category === data.text ? 'current-nav-category' : ''}
                            >
                                {data.text}
                                <ChevronIcon />
                            </NavigationMenu.Trigger>
                            <Content css={contentStyles}>
                                <ul>
                                    {data.links?.map((subData, i) => (
                                        <li key={i}>
                                            <NavigationMenu.Link
                                                asChild
                                                active={subData.text === title}
                                            >
                                                <CLink to={subData.link} css={subNavLinkStyles}>
                                                    {subData.text}
                                                    <NavChevronIcon />
                                                </CLink>
                                            </NavigationMenu.Link>
                                        </li>
                                    ))}
                                </ul>
                            </Content>
                        </NavigationMenu.Item>
                    ) : (
                        <NavigationMenu.Item key={i}>
                            <NavigationMenu.Link asChild active={data.text === title}>
                                <CLink to={data.link} css={navLinkStyles}>
                                    {data.text}
                                </CLink>
                            </NavigationMenu.Link>
                        </NavigationMenu.Item>
                    )
                )}
                {children}
            </MenuList>
        </NavigationMenu.Root>
    );
};

export const NavItem = ({ children, ...props }: { children: ReactNode; id?: string }) => (
    <NavigationMenu.Item {...props}>
        <NavigationMenu.Link asChild>{children}</NavigationMenu.Link>
    </NavigationMenu.Item>
);
