exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-about-avc-tsx": () => import("./../../../src/pages/about-us/about-avc.tsx" /* webpackChunkName: "component---src-pages-about-us-about-avc-tsx" */),
  "component---src-pages-about-us-careers-tsx": () => import("./../../../src/pages/about-us/careers.tsx" /* webpackChunkName: "component---src-pages-about-us-careers-tsx" */),
  "component---src-pages-about-us-meet-the-doctors-tsx": () => import("./../../../src/pages/about-us/meet-the-doctors.tsx" /* webpackChunkName: "component---src-pages-about-us-meet-the-doctors-tsx" */),
  "component---src-pages-about-us-mission-tsx": () => import("./../../../src/pages/about-us/mission.tsx" /* webpackChunkName: "component---src-pages-about-us-mission-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-for-vets-how-are-we-doing-tsx": () => import("./../../../src/pages/for-vets/how-are-we-doing.tsx" /* webpackChunkName: "component---src-pages-for-vets-how-are-we-doing-tsx" */),
  "component---src-pages-for-vets-our-commitment-tsx": () => import("./../../../src/pages/for-vets/our-commitment.tsx" /* webpackChunkName: "component---src-pages-for-vets-our-commitment-tsx" */),
  "component---src-pages-for-vets-referral-policy-tsx": () => import("./../../../src/pages/for-vets/referral-policy.tsx" /* webpackChunkName: "component---src-pages-for-vets-referral-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pet-owners-code-of-conduct-tsx": () => import("./../../../src/pages/pet-owners/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-pet-owners-code-of-conduct-tsx" */),
  "component---src-pages-pet-owners-faq-tsx": () => import("./../../../src/pages/pet-owners/faq.tsx" /* webpackChunkName: "component---src-pages-pet-owners-faq-tsx" */),
  "component---src-pages-pet-owners-helpful-links-tsx": () => import("./../../../src/pages/pet-owners/helpful-links.tsx" /* webpackChunkName: "component---src-pages-pet-owners-helpful-links-tsx" */),
  "component---src-pages-pet-owners-medication-refill-tsx": () => import("./../../../src/pages/pet-owners/medication-refill.tsx" /* webpackChunkName: "component---src-pages-pet-owners-medication-refill-tsx" */),
  "component---src-pages-pet-owners-patients-comfort-tsx": () => import("./../../../src/pages/pet-owners/patients-comfort.tsx" /* webpackChunkName: "component---src-pages-pet-owners-patients-comfort-tsx" */),
  "component---src-pages-pet-owners-pay-my-bill-tsx": () => import("./../../../src/pages/pet-owners/pay-my-bill.tsx" /* webpackChunkName: "component---src-pages-pet-owners-pay-my-bill-tsx" */),
  "component---src-pages-pet-owners-welcome-tsx": () => import("./../../../src/pages/pet-owners/welcome.tsx" /* webpackChunkName: "component---src-pages-pet-owners-welcome-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-shop-now-tsx": () => import("./../../../src/pages/shop-now.tsx" /* webpackChunkName: "component---src-pages-shop-now-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-addressing-the-epidemic-of-pet-obesity-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/addressing-the-epidemic-of-pet-obesity/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-addressing-the-epidemic-of-pet-obesity-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-choosing-the-right-dog-for-your-family-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/choosing-the-right-dog-for-your-family/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-choosing-the-right-dog-for-your-family-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-common-eye-problems-in-pets-a-guide-for-pet-owners-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/common-eye-problems-in-pets-a-guide-for-pet-owners/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-common-eye-problems-in-pets-a-guide-for-pet-owners-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-creating-a-fear-free-environment-for-your-pet-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/creating-a-fear-free-environment-for-your-pet/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-creating-a-fear-free-environment-for-your-pet-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-dont-eat-that-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/dont-eat-that/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-dont-eat-that-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-first-aid-tips-for-your-furry-friends-and-what-to-do-when-home-remedies-arent-enough-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/first-aid-tips-for-your-furry-friends;-and-what-to-do-when-home-remedies-aren't-enough/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-first-aid-tips-for-your-furry-friends-and-what-to-do-when-home-remedies-arent-enough-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-health-tips-for-aging-pets-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/health-tips-for-aging-pets/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-health-tips-for-aging-pets-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-importance-of-vaccinations-for-your-pets-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/importance-of-vaccinations-for-your-pets/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-importance-of-vaccinations-for-your-pets-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-keeping-your-pets-safe-during-thunderstorms-and-fireworks-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/keeping-your-pets-safe-during-thunderstorms-and-fireworks/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-keeping-your-pets-safe-during-thunderstorms-and-fireworks-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-mystery-respiratory-illness-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/mystery-respiratory-illness/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-mystery-respiratory-illness-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-paws-for-health-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/paws-for-health/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-paws-for-health-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-pet-ear-infections-prevention-and-care-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/pet-ear-infections-prevention-and-care/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-pet-ear-infections-prevention-and-care-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-preparing-your-pet-for-a-visit-to-the-veterinarian-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/preparing-your-pet-for-a-visit-to-the-veterinarian/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-preparing-your-pet-for-a-visit-to-the-veterinarian-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-recognizing-and-managing-pain-in-your-pets-a-comprehensive-guide-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/recognizing-and-managing-pain-in-your-pets-a-comprehensive-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-recognizing-and-managing-pain-in-your-pets-a-comprehensive-guide-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-urgent-care-vs-emergency-care-for-pets-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/urgent-care-vs-emergency-care-for-pets/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-urgent-care-vs-emergency-care-for-pets-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-urgent-pet-care-essentials-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/urgent-pet-care-essentials/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-urgent-pet-care-essentials-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-when-behavior-is-more-than-just-behavior-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/blog/when-behavior-is-more-than-just-behavior/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-when-behavior-is-more-than-just-behavior-index-mdx" */),
  "component---src-templates-content-tsx-content-file-path-src-content-services-diagnostic-imaging-and-lab-index-mdx": () => import("./../../../src/templates/content.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/services/diagnostic-imaging-and-lab/index.mdx" /* webpackChunkName: "component---src-templates-content-tsx-content-file-path-src-content-services-diagnostic-imaging-and-lab-index-mdx" */),
  "component---src-templates-content-tsx-content-file-path-src-content-services-internal-medicine-index-mdx": () => import("./../../../src/templates/content.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/services/internal-medicine/index.mdx" /* webpackChunkName: "component---src-templates-content-tsx-content-file-path-src-content-services-internal-medicine-index-mdx" */),
  "component---src-templates-content-tsx-content-file-path-src-content-services-surgery-services-index-mdx": () => import("./../../../src/templates/content.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/services/surgery-services/index.mdx" /* webpackChunkName: "component---src-templates-content-tsx-content-file-path-src-content-services-surgery-services-index-mdx" */),
  "component---src-templates-content-tsx-content-file-path-src-content-services-urgent-care-index-mdx": () => import("./../../../src/templates/content.tsx?__contentFilePath=/home/runner/work/advanced-veterinary-care/advanced-veterinary-care/src/content/services/urgent-care/index.mdx" /* webpackChunkName: "component---src-templates-content-tsx-content-file-path-src-content-services-urgent-care-index-mdx" */)
}

