import { AppointmentForm } from '@/AppointmentForm';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { h4, txtLine } from './styles/Typography';
import { flexCenter } from './styles/classes';

const Section = styled.section`
    position: relative;
    padding: 40px 16px;
    overflow: hidden;

    > p {
        text-align: center;
        color: ${({ theme }) => theme.colors.white} !important;

        :first-of-type {
            ${h4};
            ${flexCenter};
            ${txtLine};
            margin: 0 0 8px;
            position: relative;

            ::before {
                filter: invert(99%) sepia(100%) saturate(0%) hue-rotate(264deg) brightness(109%)
                    contrast(1000%);
            }
        }

        :last-of-type {
            margin: 0 auto 20px;
            max-width: max-content;
        }
    }

    > h3 {
        text-align: center;
        color: ${({ theme }) => theme.colors.white};
        margin: 0 auto 10px;
        position: relative;
        max-width: 790px;
    }

    > * {
        z-index: 3;
        position: relative;
    }

    > .gatsby-image-wrapper {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;

        :first-of-type {
            top: 0;
            min-height: 60%;

            ::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(34, 57, 78, 0.6);
                z-index: 1;
            }
        }

        :last-of-type {
            top: 60%;
            min-height: 40%;
        }
    }

    @media (min-width: 1024px) {
        padding: 80px 16px;

        > p {
            :last-of-type {
                margin-bottom: 40px;
            }
        }

        > h3 {
            margin: 0 auto 20px;
            max-width: 950px;
        }
    }
`;

export const Appointment = () => {
    return (
        <Section>
            <p>Appointment Form</p>
            <h3>Please complete the following form to request an appointment.</h3>
            <p>Note that your request will be confirmed by a staff member before finalizing.</p>
            <AppointmentForm />
            <StaticImage
                src="../../../assets/images/coming-soon/office-bg.jpg"
                alt="advanced veterinary care office"
                layout="fullWidth"
            />
            <StaticImage
                src="../../../assets/images/coming-soon/texture-bg.jpg"
                alt=""
                layout="fullWidth"
            />
        </Section>
    );
};
