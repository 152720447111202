import { css, Global, Theme } from '@emotion/react';
import '@fontsource/noto-serif';
import '@fontsource/poppins';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4, h5 } from './Typography';

export const theme: Theme = {
    colors: {
        white: '#FFF',
        primary: '#2C80C3',
        secondary: '#9BC444',
        gradient: 'linear-gradient(124.93deg, #2C80C3 0%, #2A4154 87.6%)',
        light: '#F7F8F5',
        lightBlue: '#7293BC',
        dark: '#0F1115',
        darkGradient: 'radial-gradient(109.06% 50.16% at 50% 50.16%, #1B364B 0%, #0E0F13 100%)',
        gray: '#514F4F',
        black: '#353633',
    },
    spacing: {
        md: '2.778vw', //40px at 1440px wide viewport
        lg: '5.556vw', //80px ^^
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                }
                body {
                    color: ${theme.colors.gray};
                    font-family: 'Poppins', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 162%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                }

                h4 {
                    ${h4};
                }

                h5 {
                    ${h5};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.gray};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                @media (min-width: 1280px) {
                    body {
                        font-size: 1.125rem;
                    }
                }
            `}
        />
    );
};
