import { Fragment } from 'react';

export const siteInfo = {
    phone: {
        text: '(407)-465 7000',
        link: 'tel:+14074657000',
    },
    email: {
        link: 'mailto:info@avcorlando.com',
        text: 'info@avcorlando.com',
    },
    address: {
        text: (
            <Fragment>
                8010 W. Colonial Drive Orlando FL 32818
                <br />
                Suite #100
            </Fragment>
        ),
        link: 'https://goo.gl/maps/Tpi6rrtLCLVUgbyT9',
    },
    social: {
        facebook: 'https://www.facebook.com/avcorlando/',
        instagram: 'https://www.instagram.com/avcorlando/',
    },
};

export type NavLinksTypes = { text: string; link: string; links?: NavLinksTypes }[];

export const navLinks: NavLinksTypes = [
    {
        text: 'about us',
        link: '',
        links: [
            {
                text: 'about AVC',
                link: '/about-us/about-avc/',
            },
            {
                text: 'meet the doctors',
                link: '/about-us/meet-the-doctors/',
            },
            {
                text: 'mission',
                link: '/about-us/mission/',
            },
            {
                text: 'careers',
                link: '/about-us/careers/',
            },
        ],
    },
    {
        text: 'services',
        link: '/services/',
        links: [
            {
                text: 'urgent care',
                link: '/services/urgent-care/',
            },
            {
                text: 'internal medicine',
                link: '/services/internal-medicine/',
            },
            {
                text: 'surgery services',
                link: '/services/surgery-services/',
            },
            {
                text: 'diagnostic imaging & lab',
                link: '/services/diagnostic-imaging-and-lab/',
            },
        ],
    },
    {
        text: 'for vets',
        link: '',
        links: [
            {
                text: 'referral policy',
                link: '/for-vets/referral-policy/',
            },
            {
                text: 'our commitment',
                link: '/for-vets/our-commitment/',
            },
            {
                text: 'how are we doing?',
                link: '/for-vets/how-are-we-doing/',
            },
        ],
    },
    {
        text: 'pet owners',
        link: '',
        links: [
            {
                text: 'medication refill',
                link: '/pet-owners/medication-refill/',
            },
            {
                text: 'welcome',
                link: '/pet-owners/welcome/',
            },
            {
                text: 'pay my bill',
                link: '/pet-owners/pay-my-bill/',
            },
            {
                text: 'helpful links',
                link: '/pet-owners/helpful-links/',
            },
            {
                text: 'FAQ',
                link: '/pet-owners/faq/',
            },
            {
                text: 'blog',
                link: '/blog/',
            },
            {
                text: 'code of conduct',
                link: '/pet-owners/code-of-conduct/',
            },
            // {
            //     text: 'patient’s comfort',
            //     link: '/pet-owners/patients-comfort/',
            // },
        ],
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
    {
        text: siteInfo.phone.text,
        link: siteInfo.phone.link,
    },
    {
        text: 'book appointment',
        link: '#appointment',
    },
];

export const footerNavLinks: NavLinksTypes = [
    {
        text: 'appointment',
        link: '#appointment',
    },
    {
        text: 'about AVC',
        link: '/about-us/about-avc/',
    },
    {
        text: 'our services',
        link: '/services/',
    },
    {
        text: 'mission',
        link: '/about-us/mission/',
    },
    {
        text: 'meet the doctors',
        link: '/about-us/meet-the-doctors/',
    },
    {
        text: 'Medication Refill',
        link: '/pet-owners/medication-refill/',
    },
    {
        text: 'FAQ',
        link: '/pet-owners/faq/',
    },
    {
        text: 'careers',
        link: '/about-us/careers/',
    },
    {
        text: 'contact us',
        link: '/contact-us/',
    },
    {
        text: 'privacy policy',
        link: '/privacy-policy/',
    },
];

const flatLinks = (() => {
    const flat: NavLinksTypes = [];

    const pushFlat = (arr: NavLinksTypes) =>
        arr.forEach(data => {
            if (!data.links) flat.push(data);
            else {
                flat.push({
                    text: data.text,
                    link: data.link,
                });
                pushFlat(data.links);
            }
        });

    pushFlat(navLinks);
    return flat;
})();

export function getNavLink(text: string) {
    const link = text.replaceAll('-', ' ').toLowerCase();
    const linkObj = flatLinks.find(data => data.text.toLowerCase() === link);

    if (linkObj) return linkObj.link;
    else return '';
}
