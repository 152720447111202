import { ReactComponent as ArrowIcon } from '@a/icons/right-arrow.svg';
import styled from '@emotion/styled';
import { ElementType, ReactNode } from 'react';
import PlainBtn from './PlainBtn';

const Btn = styled(PlainBtn)<{ big?: boolean; green?: boolean }>`
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.black};
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    > svg {
        margin-left: 10px;

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    :hover {
        color: ${({ theme }) => theme.colors.white};

        > svg path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }

    :focus-visible {
        color: ${({ theme }) => theme.colors.white};

        > svg path {
            fill: ${({ theme }) => theme.colors.white};
        }
    }
`;

type BtnProps = React.HTMLAttributes<HTMLButtonElement> & {
    as?: ElementType;
    children: ReactNode;
    to?: string;
    state?: object;
};

export default function SecondaryBtn({ children, ...props }: BtnProps) {
    return (
        <Btn {...props}>
            {children} <ArrowIcon />
        </Btn>
    );
}
