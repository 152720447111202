import styled from '@emotion/styled';
import PlainBtn from './PlainBtn';

export default styled(PlainBtn)<{ big?: boolean }>`
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
    isolation: isolate;
    position: relative;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: ${({ theme }) => theme.colors.gradient};
        z-index: -1;
        opacity: 0;
        border-radius: inherit;
        transition: opacity 0.3s ease-in-out;
    }

    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    :hover {
        ::before {
            opacity: 1;
        }
    }

    :focus-visible {
        ::before {
            opacity: 1;
        }
    }
`;
